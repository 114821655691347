exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-expertise-js": () => import("./../../../src/pages/expertise.js" /* webpackChunkName: "component---src-pages-expertise-js" */),
  "component---src-pages-firm-js": () => import("./../../../src/pages/firm.js" /* webpackChunkName: "component---src-pages-firm-js" */),
  "component---src-pages-impact-js": () => import("./../../../src/pages/impact.js" /* webpackChunkName: "component---src-pages-impact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insight-js": () => import("./../../../src/pages/insight.js" /* webpackChunkName: "component---src-pages-insight-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-archimed-med-platform-ii-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/darrazo/Rasget/orous-website/news/archimed-med-platform-ii/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-archimed-med-platform-ii-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-conf-african-ambas-expert-saudi-invest-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/darrazo/Rasget/orous-website/news/conf-african-ambas-expert-saudi-invest/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-conf-african-ambas-expert-saudi-invest-index-mdx" */),
  "component---src-pages-team-christian-duroux-js": () => import("./../../../src/pages/team/christian-duroux.js" /* webpackChunkName: "component---src-pages-team-christian-duroux-js" */),
  "component---src-pages-team-fadoua-bikdir-js": () => import("./../../../src/pages/team/fadoua-bikdir.js" /* webpackChunkName: "component---src-pages-team-fadoua-bikdir-js" */),
  "component---src-pages-team-h-e-mubarak-bin-hamoodah-js": () => import("./../../../src/pages/team/h-e-mubarak-bin-hamoodah.js" /* webpackChunkName: "component---src-pages-team-h-e-mubarak-bin-hamoodah-js" */),
  "component---src-pages-team-houssam-nasrawin-js": () => import("./../../../src/pages/team/houssam-nasrawin.js" /* webpackChunkName: "component---src-pages-team-houssam-nasrawin-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-team-jean-marie-bockel-js": () => import("./../../../src/pages/team/jean-marie-bockel.js" /* webpackChunkName: "component---src-pages-team-jean-marie-bockel-js" */),
  "component---src-pages-team-najat-benchiba-savenius-js": () => import("./../../../src/pages/team/najat-benchiba-savenius.js" /* webpackChunkName: "component---src-pages-team-najat-benchiba-savenius-js" */),
  "component---src-pages-team-yassine-afia-js": () => import("./../../../src/pages/team/yassine-afia.js" /* webpackChunkName: "component---src-pages-team-yassine-afia-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

